import React, { useState } from 'react';
import Layout from '../../components/Layout';
import NavOne from '../../components/NavOne';
import ModalVideo from 'react-modal-video';
import PageHeader from '../../components/PageHeader';
import ClientStripe from '../../assets/images/circle-stripe.png';
import testimonial1 from '../../assets/images/videos/testimonial2.png';
import Footer from '../../components/Footer';

const Content = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <section className='about-one '>
      <img src={ClientStripe} className='about-one__circle' alt='' />
      <div className='container text-center'>
        <div className='block-title text-center'>
          <h2 className='block-title__title'>Patient Testimonials</h2>
        </div>
        <div className='col-lg-4'>
          <div
            className='video-three__box'
            style={{ backgroundImage: `url(${testimonial1})` }}
          >
            <h2 className='video-three__title'>
              FIDA <br />
              Member
            </h2>
            <div className='video-three__btn-box'>
              <ModalVideo
                channel='youtube'
                isOpen={isOpen}
                videoId='fsIAebmKEc0'
                onClose={() => setIsOpen(false)}
              />
              <div
                onClick={() => setIsOpen(true)}
                onKeyDown={() => setIsOpen(true)}
                role='button'
                tabIndex={0}
                className='video-three__popup'
              >
                <i className='fas fa-play'></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const Videos = () => {
  return (
    <Layout pageTitle='Videos | LUTH'>
      <NavOne />
      <PageHeader title='' />
      <Content />
      <Footer />
    </Layout>
  );
};

export default Videos;
